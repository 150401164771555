import { getAuthServerUrl } from './database';
import { getSiteMode } from './siteMode';

export const loginWith = (method: string) => {
  const href = window.location.href;
  const authServerUrl = `${getAuthServerUrl()}/auth/${method}`;
  let referrer = '';

  if (href.includes('localhost')) {
    referrer = 'localhost-3000';
  } else if (
    href.startsWith('https://srpatel-teuteuf-accounts.whentaken.pages.dev')
  ) {
    referrer = 'whentaken-staging';
  } else if (getSiteMode() === 'wheretaken') {
    referrer = 'wheretaken-2.0';
  } else if (getSiteMode() === 'whentaken-movies') {
    referrer = 'whentaken-movies';
  } else {
    referrer = 'whentaken';
  }

  window.location.href = `${authServerUrl}?referer=${referrer}`;
  return;
};
